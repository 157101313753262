import React from 'react';
import styled from 'styled-components';
import { Input, Divider } from 'antd';
import { Field } from 'react-final-form';

const ErrorForm = styled.span`
  color: red;
  padding-bottom: 0px;
`;

const CustomInput = styled(Input)`
`;

const CustomInputPassword = styled(Input.Password)`
`;

const LoginText = styled.span`
  font-family: 'Myriad Pro', Myriad, 'Liberation Sans', 'Nimbus Sans L',
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 100%;
  color: #a5a4a4;
  padding-top: 5%;
`;

const FormDivider = styled(Divider)`
  margin: 0px;
  padding-bottom: 5px;
`;

interface Props {
  name: string;
  placeholder?: string;
  iconType?: string;
  disabled?: boolean;
  password?: boolean;
}

export const FormField = ({ name, placeholder, iconType, disabled }: Props) => (
  <Field name={name}>
    {({ input, meta }) => (
      <>
        <LoginText>{placeholder}</LoginText>
        <CustomInput bordered={false} disabled={disabled} {...input} />
        <FormDivider />
        {meta.error && meta.touched && <ErrorForm>{meta.error}</ErrorForm>}
      </>
    )}
  </Field>
);

export const FormPasswordField = ({
  name,
  placeholder,
  iconType,
  disabled
}: Props) => (
  <Field name={name}>
    {({ input, meta }) => (
      <>
        <LoginText>{placeholder}</LoginText>
        <CustomInputPassword bordered={false} disabled={disabled} {...input} />
        <FormDivider />
        {meta.error && meta.touched && <ErrorForm>{meta.error}</ErrorForm>}
      </>
    )}
  </Field>
);
