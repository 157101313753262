export default {
  // USERS
  USERS_REQUESTED: 'USERS_REQUESTED',
  USERS_LOADING: 'USERS_LOADING',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_ERROR: 'USERS_ERROR',
  USERS_CLEAR: 'USERS_CLEAR',
  MY_USER_REQUESTED: 'MY_USER_REQUESTED',
  MY_USER_LOADING: 'MY_USER_LOADING',
  MY_USER_SUCCESS: 'MY_USER_SUCCESS',
  MY_USER_ERROR: 'MY_USER_ERROR',
  USER_UPDATE_REQUESTED: 'USER_UPDATE_REQUESTED',
  USER_UPDATE_LOADING: 'USER_UPDATE_LOADING',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
  USER_UPDATE_CLEAR: 'USER_UPDATE_CLEAR',
  USER_ADD_REQUESTED: 'USER_ADD_REQUESTED',
  USER_ADD_LOADING: 'USER_ADD_LOADING',
  USER_ADD_SUCCESS: 'USER_ADD_SUCCESS',
  USER_ADD_ERROR: 'USER_ADD_ERROR',
  USER_ADD_CLEAR: 'USER_ADD_CLEAR',
};
