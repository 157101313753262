import React from 'react';
import styled from 'styled-components';
import { Modal, Typography, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store';
import Actions from '../../../actions';

interface Props {
  selectedUser?: {
    email?: string;
    id?: number;
    is_enable?: boolean;
  };
  visible: boolean;
  closeModal?: () => void;
}

const { Title } = Typography;

const CustomTitle = styled(Title)`
  font-weight: 400 !important;
`;

const CustomModal = styled(Modal)`
  .ant-btn {
    color: #ff3333;
    > span {
      text-decoration: underline;
    }
    :hover,
    :focus {
      color: #ff5753;
    }
  }
  .ant-btn-primary {
    color: white;
    background-color: #9ea9a7;
    > span {
      text-decoration: none;
    }
    :hover,
    :focus {
      background-color: #c5c5c4;
    }
  }
  .ant-modal-footer {
    padding: 15px 20px;
  }
`;

const ManageAccessUserModal = ({
  selectedUser,
  visible,
  closeModal,
}: Props) => {
  const dispatch = useDispatch();
  const updateUserState = useSelector((state: State) => state.Api.updateUser);
  const loading = updateUserState.status === null;

  const updateUser = (userId: number | undefined, data: object) => {
    dispatch(Actions.Users.updateUser(userId, data));
  };

  return (
    <CustomModal
      title='Are you sure?'
      visible={visible}
      onCancel={closeModal}
      onOk={() => updateUser(selectedUser?.id, { is_enable: !selectedUser?.is_enable })}
      okText={selectedUser?.is_enable ? 'Revoke Access' : 'Grant Access'}
      confirmLoading={loading}
    >
      <CustomTitle level={5}>
        You are about to {selectedUser?.is_enable ? 'revoke' : 'grant'} access for user{' '}
        <span style={{ color: 'black' }}>'{selectedUser?.email}'</span>. All
        access for this user will be {selectedUser?.is_enable ? 'revoked' : 'granted'}.
      </CustomTitle>
      {updateUserState.status
        ?
        <Alert message='User permissions was changed successfully!' type='success'/>
        :
        <div>
          {updateUserState.error && (
            <Alert message={updateUserState.error} type='error'/>
          )}
        </div>}
    </CustomModal>
  );
};

export default ManageAccessUserModal;
