import { takeLatest } from 'redux-saga/effects';
import Auth from './auth';
import Constants from '../constants';
import Categories from './categories';
import Quicksight from './quicksight';
import Users from './users';

export default {
  // Auth
  watchAuthLogin: function*() {
    yield takeLatest(Constants.AUTH_LOGIN_REQUESTED, Auth.loginSaga);
  },
  watchAuthCompleteNewPassword: function*() {
    yield takeLatest(Constants.AUTH_COMPLETE_NEW_PASSWORD_REQUESTED, Auth.completeNewPasswordSaga);
  },
  watchAuthRegister: function*() {
    yield takeLatest(Constants.AUTH_REGISTER_REQUESTED, Auth.registerSaga);
  },
  watchAuthLogout: function*() {
    yield takeLatest(Constants.AUTH_LOGOUT_REQUESTED, Auth.logoutSaga);
  },
  watchAuthRequestResetPass: function*() {
    yield takeLatest(
      Constants.AUTH_RESET_PASS_CODE_REQUEST_REQUESTED,
      Auth.requestResetPasswordSaga
    );
  },
  watchAuthRestorePassword: function*() {
    yield takeLatest(
      Constants.AUTH_RESET_PASS_SUBMIT_REQUESTED,
      Auth.restorePasswordSaga
    );
  },
  watchAuthChangePassword: function*() {
    yield takeLatest(
      Constants.AUTH_CHANGE_PASS_SUBMIT_REQUESTED,
      Auth.changePasswordSaga
    );
  },
  watchAuthGetToken: function*() {
    yield takeLatest(
      Constants.AUTH_GET_TOKEN_FROM_LS_REQUESTED,
      Auth.getTokenSaga
    );
  },
  watchAuthGetUserID: function*() {
    yield takeLatest(
      Constants.AUTH_GET_USER_ID_FROM_LS_REQUESTED,
      Auth.getUserSaga
    );
  },
  watchGetCategories: function*() {
    yield takeLatest(
      Constants.CATEGORIES_REQUESTED,
      Categories.getCategoriesSaga
    );
  },
  watchGetQuicksight: function*() {
    yield takeLatest(
      Constants.QUICKSIGHT_REQUESTED,
      Quicksight.getQuicksightSaga
    );
  },
  watchGetUsers: function*() {
    yield takeLatest(
      Constants.USERS_REQUESTED,
      Users.getUsersSaga
    );
  },
  watchGetMyUser: function*() {
    yield takeLatest(
      Constants.MY_USER_REQUESTED,
      Users.getMyUserSaga
    );
  },
  watchUpdateUser: function*() {
    yield takeLatest(
      Constants.USER_UPDATE_REQUESTED,
      Users.updateUserSaga
    );
  },
  watchAddUser: function*() {
    yield takeLatest(
      Constants.USER_ADD_REQUESTED,
      Users.addUserSaga
    );
  }
};
