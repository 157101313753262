import Auth from './auth';
import Categories from './categories';
import Quicksight from './quicksight';
import Users from './users';

export default {
  Auth,
  Categories,
  Quicksight,
  Users
};
