import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Button, Input, Typography, Alert, Spin } from 'antd';
import Actions from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from 'aws-amplify';
import { State } from '../../../store';
import { isFetching } from '../../../misc/StateInfos';

interface Props {
  data?: string | undefined;
}

const ItemContainer = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  padding-left: 20px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  .ant-typography {
    padding-bottom: 0 !important;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-btn {
    margin-left: 0 !important;
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const BasicInformationContainer = styled.div`
  padding: 0px 20px;
  padding-left: 0px;
  min-width: 25%;
  @media (max-width: 700px) {
    padding 0px;
  }
`;

const PasswordChangeContainer = styled.div`
  min-width: 25%;
  padding: 0px 20px;
  @media (max-width: 700px) {
    padding 0px;
  }
`;

const ListTypography = styled(Typography)`
  font-size: 12px;
  padding-left: 5px;
  font-weight: 300;
  display: inline;
`;

const NewsletterLink = styled(Typography)`
  font-size: 12px;
  font-style: italic;
  padding: 2px;
`;

const CustomInput = styled(Input)`
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 1px #d7d7d7;
`;
const CustomInputPassword = styled(Input.Password)`
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 1px #d7d7d7;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 1em;
  text-indent: -1em;
  margin: 0;
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const { Title } = Typography;

const passwordConstraints = ['One lowercase character','One uppercase character','One number','8 characters minimum'];

const isPasswordsMatching = (password: string, passwordValidation: string) =>
  passwordValidation === password;

const AccountSettings = ({ data }: Props) => {
  const dispatch = useDispatch();
  const [userState, setUserState] = useState(undefined);
  const changePasswordState = useSelector((state: State) => state.Auth.change_password);
  const basicInfoState = useSelector((state: State) => state.Api.myUser);
  const updatedBasicInfoState = useSelector((state: State) => state.Api.updateUser);
  const staticBasicInfo: any = basicInfoState.data;
  const loading = basicInfoState.status === null;

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(res => {
      setUserState(res);
    });
    dispatch(Actions.Users.getMyUser());
    return () => {
      dispatch(Actions.Users.updateUserClear());
      dispatch(Actions.Auth.clear.resetPass());
    };
    // eslint-disable-next-line
  }, []);

  const changePassword = (user: CognitoUser | undefined, oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    if (isPasswordsMatching(newPassword, confirmNewPassword)) {
      dispatch(Actions.Auth.resetPass.change(user, oldPassword, newPassword));
    }
  };

  const onSubmitPasswordChange = (data: any) => {
    changePassword(userState, data.oldPassword, data.newPassword, data.confirmNewPassword);
  };

  const changeBasicInfo = (data: object) => {
    dispatch(Actions.Users.updateUser(undefined, data));
  };

  const onSubmitBasicInfoChange = (data: any) => {
    changeBasicInfo(data);
  };

  return (
    <ItemContainer>
      {!loading ?
        <DetailsContainer>
          <BasicInformationContainer>
            <Title level={4}>Basic Information</Title>
            <Form onFinish={onSubmitBasicInfoChange} layout='vertical'>
              <Form.Item
                name='email'
                initialValue={staticBasicInfo?.email}
                label={
                  <Typography>Email Address</Typography>
                }>
                <CustomInput disabled/>
              </Form.Item>
              <Form.Item
                name='first_name'
                initialValue={staticBasicInfo?.first_name}
                label={
                  <Typography>First Name</Typography>
                }
              >
                <CustomInput/>
              </Form.Item>
              <Form.Item
                name='last_name'
                initialValue={staticBasicInfo?.last_name}
                label={
                  <Typography>Last Name</Typography>
                }>
                <CustomInput/>
              </Form.Item>
              {updatedBasicInfoState.status
                ?
                <Alert message='Basic information were changed successfully!' type='success'/>
                :
                <div>
                  {updatedBasicInfoState.error && (
                    <Alert message={updatedBasicInfoState.error} type='error'/>
                  )}
                </div>}
              <Button
                htmlType='submit'
              >
                Update
              </Button>
            </Form>
            <NewsletterLink>Subscribe to our Monthly Newsletter <a
              href='http://eepurl.com/gbbWsb'>Here</a></NewsletterLink>
          </BasicInformationContainer>
          <PasswordChangeContainer>
            <Title level={4}>Password Change</Title>
            <Form onFinish={onSubmitPasswordChange} layout='vertical'>
              <Form.Item
                rules={[{ required: true, message: 'Please input your Password!' }]}
                label={
                  <Typography>Verify Current Password</Typography>
                } name='oldPassword'>
                <CustomInputPassword/>
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: 'Please input your new Password!' },
                  { pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, message: 'Wrong format!' }
                ]}
                label={
                  <Typography>New Password</Typography>
                } name='newPassword'>
                <CustomInputPassword/>
              </Form.Item>
              <List>
                {passwordConstraints.map((constraint, index) => (
                  <li>
                    <svg height='11' width='11'>
                      <circle cx='5.5' cy='5.5' r='5' stroke='#7d7e7e' stroke-width='0.5' fill='none'/>
                    </svg>
                    <ListTypography key={index}>{constraint}</ListTypography>
                  </li>
                ))}
              </List>
              <Form.Item
                rules={[
                  { required: true, message: 'Please confirm your new Password!' },
                  { pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, message: 'Wrong format!' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  })
                ]}
                label={
                  <Typography>Confirm New Password</Typography>
                } name='confirmNewPassword'>
                <CustomInputPassword/>
              </Form.Item>
              {changePasswordState.status
                ?
                <Alert message='Password was changed successfully!' type='success'/>
                :
                <div>
                  {changePasswordState.error && (
                    <Alert message={changePasswordState.error} type='error'/>
                  )}
                </div>}
              <Button
                htmlType='submit'
                loading={isFetching(changePasswordState)}
              >
                Update
              </Button>
            </Form>
          </PasswordChangeContainer>
        </DetailsContainer>
        :
          <StyledSpin size='large' />
      }
    </ItemContainer>
  );
};

export default AccountSettings;
