import Misc from './misc';
import { CognitoUser } from "amazon-cognito-identity-js";

const Auth = Misc.Auth;

const completeNewPassword = async (
  email: string,
  oldPassword: string,
  newPassword: string
) => {
  const signInData: any = await Auth.signIn(email, oldPassword)
    .then((user) => ({ success: true, user: user }))
    .catch((err) => {
      return { success: false, data: { error: err.message } };
    });
  if (signInData.success) {
    const completeNewPasswordData: any = await Auth.completeNewPassword(
      signInData.user,
      newPassword,
      signInData.user.challengeParam.requiredAttributes
    )
      .then((newUserData) => {
        return { success: true, data: { newUserData } };
      })
      .catch((err) => {
        return { success: false, data: { error: err.message } };
      });
    Auth.signOut().then(data => {
      console.log('Logged out');
    });
    return completeNewPasswordData;
  } else {
    return signInData;
  }
};

const login = (email: string, password: string) =>
  Auth.signIn(email, password)
    .then(user => {
      if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return {
          success: false,
          data: { email: email, password: password, error: 'NEW_PASSWORD_REQUIRED' }
        };
      }
      return Auth.currentSession()
        .then(data => {
          if (!data.getIdToken().decodePayload().email_verified)
            return {
              success: false,
              data: { error: 'Account not verified, check your mails.' }
            };
          return {
            success: true,
            data: { user, token: data }
          };
        })
        .catch(err => ({ success: false, data: { error: err.message } }));
    })
    .catch(err => ({ success: false, data: { error: err.message } }));

const getToken = () =>
  Auth.currentSession()
    .then(data => {
      return {
        success: true,
        data: {
          token: data.getAccessToken().getJwtToken(),
          tokenID: data.getIdToken().getJwtToken()
        }
      };
    })
    .catch(err => {
      return { success: false, data: { error: err } };
    });

const getUser = () =>
  Auth.currentUserInfo()
    .then(data => {
      return {
        success: true,
        data: data
      };
    })
    .catch(err => {
      return { success: false, data: { error: err.message } };
    });

const logout = () =>
  Auth.signOut()
    .then(data => ({ success: true, data }))
    .catch(err => ({ success: false, data: { error: err.message } }));

const register = (body: any) =>
  Auth.signUp({
    username: body.email,
    password: body.password,
    attributes: {
      email: body.email,
      'custom:customer_id': body.token
    }
  })
    .then(data => ({ success: true, data }))
    .catch(err => ({ success: false, data: { error: err.message } }));

const verifyRegistration = (email: string, code: string) =>
  Auth.confirmSignUp(email, code)
    .then(data => ({ success: true, data }))
    .catch(err => ({ success: false, data: { error: err.message } }));

const forgotPassword = (username: string) =>
  Auth.forgotPassword(username)
    .then(data => ({ success: true, data }))
    .catch(err => ({ success: false, data: { error: err.message } }));

const restorePassword = (username: string, code: string, password: string) =>
  Auth.forgotPasswordSubmit(username, code, password)
    .then(data => ({ success: true, data }))
    .catch(err => ({ success: false, data: { error: err.message } }));

const changePassword = (user: CognitoUser | any, oldPassword: string, newPassword: string) =>
  Auth.changePassword(user, oldPassword, newPassword)
    .then(data => ({ success: true, data }))
    .catch(err => ({ success: false, data: { error: err.message } }));

const refreshToken = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (err: any, session: any) => {}
    );
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
};

export default {
  login,
  completeNewPassword,
  logout,
  getToken,
  register,
  verifyRegistration,
  forgotPassword,
  restorePassword,
  changePassword,
  refreshToken,
  getUser
};
