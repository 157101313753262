import { put, call } from 'redux-saga/effects';
import Constants from '../constants';
import API from '../api';

export function* getCategoriesSaga() {
  try {
    yield put({ type: Constants.CATEGORIES_LOADING });
    const {
      data: { token, tokenID }
    } = yield API.Auth.getToken();

    const res = yield call(API.Categories.getCategories, token, tokenID);
    if (res.success && Array.isArray(res.data)) {
      const categories = res.data.map(category => {
        const contentList = [
          ...category.article_list,
          ...category.quicksight_list
        ];
        contentList.sort((a, b) => {
          return a.order - b.order;
        });
        return {
          id: category.id,
          name: category.name,
          order: category.order,
          contentList
        };
      });
      yield put({ type: Constants.CATEGORIES_SUCCESS, data: categories });
    } else if (res.hasOwnProperty('error')) {
      throw Error(res.error);
    } else throw Error('Error with request');
  } catch (err) {
    yield put({
      type: Constants.CATEGORIES_ERROR,
      error: 'Service unavailable.'
    });
  }
}

export default {
  getCategoriesSaga
};
