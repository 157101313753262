import React, { useState } from 'react';
import { Button, Alert, Modal, Popover } from 'antd';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Actions from '../actions';
import { State } from '../store';
import { FormField, FormPasswordField } from '../components/FormFields';
import { isFetching } from '../misc/StateInfos';
import PasswordRequirement from './MainPage/PasswordRequirement';

import protagonistLogo from '../Images/Protagonist Logo_Horizontal.svg';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ResetPasswordSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Logo = styled.img`
  padding-top: 2%;
  padding-bottom: 2%;
  width: 74%;
  height: auto;
`;

const WelcomeText = styled.text`
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-weight: 600;
  font-size: 140%;
  padding-top: 10%;
`;

const CustomForm = styled.form`
  width: 100%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
`;

const EmailFieldDescription = styled.span`
  font-family: 'Myriad Pro', Myriad, 'Liberation Sans', 'Nimbus Sans L',
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 100%;
  color: #a5a4a4;
  padding-bottom: 10%;
`;

type FormValues = {
  email?: string;
  code?: string;
  password?: string;
  passwordValidation?: string;
};

const isPasswordsMatching = (values: FormValues) =>
  values.passwordValidation === values.password;

const ResetPassword = ({ history }: RouteComponentProps) => {
  const [confirmation, setConfirmation] = useState(false);
  const dispatch = useDispatch();
  const resetPasswordState = useSelector(
    (state: State) => state.Auth.reset_password
  );
  const isRequested = resetPasswordState.codeRequest.status;

  const onSubmitRequest = (values: FormValues) => {
    if (values.email === undefined) {
      return;
    }
    dispatch(Actions.Auth.resetPass.request(values.email));
  };

  const onSubmitRestore = (values: FormValues) => {
    if (
      values.email === undefined ||
      values.code === undefined ||
      values.password === undefined
    ) {
      return;
    }
    if (isPasswordsMatching(values)) {
      dispatch(
        Actions.Auth.resetPass.restore(
          values.email,
          values.code,
          values.password
        )
      );
    }
  };

  const validateForm = (values: FormValues) => {
    const errors: FormValues = {
      email: undefined,
      code: undefined,
      password: undefined,
      passwordValidation: undefined,
    };
    if (isRequested === false) {
      if (!values.email) {
        errors.email = 'Email required.';
      }
    } else {
      if (!values.code) {
        errors.code = 'Code required.';
      }
      if (!values.password) {
        errors.password = 'Password required.';
      }
      if (!values.passwordValidation) {
        errors.passwordValidation = 'Password validation required.';
      }
      if (
        values.password &&
        values.passwordValidation &&
        values.password !== values.passwordValidation
      ) {
        errors.passwordValidation = 'Passwords must match.';
      }
    }
    return errors;
  };

  React.useEffect(() => {
    if (!confirmation && isRequested) {
      setConfirmation(true);
      Modal.success({
        title: 'Verification Code Sent!',
        content:
          'You should have received a code in your inbox, use the code to fill the rest of the form to change your password.',
      });
    }
  }, [confirmation, isRequested]);

  React.useEffect(() => {
    if (resetPasswordState.passSubmit.status) {
      setConfirmation(true);
      Modal.success({
        title: 'Password changed !',
        content: 'Your password has been changed.',
        onOk: () => {
          dispatch(Actions.Auth.clear.resetPass());
          history.push('/');
        },
        zIndex: 1040,
      });
    }
  }, [resetPasswordState.passSubmit.status, dispatch, history]);

  return (
    <ResetPasswordSection>
      <LogoContainer>
        <Logo src={protagonistLogo} />
        <WelcomeText>Reset Password</WelcomeText>
      </LogoContainer>
      <FormContainer>
        <Form
          onSubmit={isRequested ? onSubmitRestore : onSubmitRequest}
          validate={validateForm}
        >
          {({ handleSubmit, submitting, pristine, values }) => {
            return (
              <CustomForm onSubmit={handleSubmit} className='login-form'>
                <FormField
                  name='email'
                  placeholder='Enter your email.'
                  iconType='user'
                  disabled={isRequested}
                />
                {isRequested ? (
                  <>
                    <FormField
                      name='code'
                      placeholder='Enter verification code.'
                      iconType='qrcode'
                    />
                    <Popover
                      placement='right'
                      content={
                        <PasswordRequirement
                          password={values.password}
                          passwordValidation={values.passwordValidation}
                        />
                      }
                      trigger='focus'
                      title='Password must:'
                      visible={!!values.password}
                    >
                      <FormPasswordField
                        name='password'
                        placeholder='Enter new password.'
                        iconType='lock'
                      />
                    </Popover>
                    <FormPasswordField
                      name='passwordValidation'
                      placeholder='Enter new password again.'
                      iconType='lock'
                    />
                  </>
                ) : (
                  <EmailFieldDescription>
                    A verification code will be sent to the email associated
                    with you
                  </EmailFieldDescription>
                )}
                {(resetPasswordState.codeRequest.error ||
                  resetPasswordState.passSubmit.error) && (
                  <Alert
                    message={
                      resetPasswordState.codeRequest.error ||
                      resetPasswordState.passSubmit.error
                    }
                    type='error'
                  />
                )}
                <Button
                  type='primary'
                  icon=''
                  htmlType='submit'
                  loading={
                    isFetching(resetPasswordState.codeRequest) ||
                    isFetching(resetPasswordState.passSubmit)
                  }
                >
                  {isRequested ? 'Submit' : 'Request verification code'}
                </Button>
                <Button
                  type='ghost'
                  icon=''
                  onClick={() => {
                    dispatch(Actions.Auth.clear.resetPass());
                    history.push('/');
                  }}
                >
                  Back to log in
                </Button>
              </CustomForm>
            );
          }}
        </Form>
      </FormContainer>
    </ResetPasswordSection>
  );
};

export default withRouter(ResetPassword);
