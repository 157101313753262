import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'antd';

import sentimentImg from '../Images/LoginSentimentImg.svg';
import forecastImg from '../Images/LoginForecastImg.svg';

const CustomCarousel = styled(Carousel)`
  flex-direction: column;

  > .slick-dots li button {
    width: 70%;
    height: 70%;
    border-radius: 50%;
  }
  > .slick-dots li {
    margin: 0.5%;
    width: 10px;
    height: 10px;
  }
  > .slick-dots li.slick-active {
    width: 10px;
  }
  .slick-dots {
    margin-top: 3%;
    position: static !important;
  }
`;

const InfoCard = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`;

const InfoImg = styled.img`
  padding-bottom: 7%;
  width: 85%;
  height: auto;
`;

const DescriptionSpan = styled.span`
    font-size: '100%';
    font-family: 'Open Sans';
    color: white;
    @media (max-height: 400px) {
        display: none;
    }
`;

const InfoCards = [
  {
    img: sentimentImg,
    title: 'Understand Audience Pain Points',
    description:
      'Our sentiment analysis highlights the trending pain points and satisfaction drivers your audience is experience for the current month and beyond'
  },
  {
    img: forecastImg,
    title: 'Access to the Latest Insights',
    description:
      'See the monthly trends, themes and topics that matter most to your audience'
  }
];

const GenerateInfoCard = (img: any, title: string, description: string, index: number) => {
    return (
      <InfoCard key={index}>
        <InfoImg style={{margin: 'auto'}} src={img} />
        <div style={{marginRight: '20%', marginLeft: '20%'}}>
          <h2 style={{ fontFamily: 'Open Sans, sans-serif', color: 'white'}}>
            {title}
          </h2>
          <DescriptionSpan>
            {description}
          </DescriptionSpan>
        </div>
      </InfoCard>
    );
  };

const InfoCarousel = () => {
  return (
      <CustomCarousel>
            {InfoCards.map((value, index) =>
              GenerateInfoCard(value.img, value.title, value.description, index)
            )}
      </CustomCarousel>
  );
};

export default InfoCarousel;
