import Constants from '../constants';

export default {
  getCategories: () => ({
    type: Constants.CATEGORIES_REQUESTED
  }),
  clear: () => ({
    type: Constants.CATEGORIES_CLEAR
  })
};
