import React from 'react';
import styled from 'styled-components';

import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import logoHead from '../../Images/Protagonist Logo Head_White.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 7vh;
  background-color: #E11F2D;
`;

const SideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 80px;
  height: 100%;
`;

const Logo = styled.img`
  margin-left: 40px;
  height: 2vh;
  width: auto;
  @media (max-width: 700px) {
    display: none;
  }
`;

const LogoHead = styled.img`
height: 100%;
padding: 0.5% 2%;
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
`;

const StyledSpan = styled.span`
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 700;
  font-size: 2em;
  @media (max-width: 700px) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  font-weight: 600;
  font-size: 17px;
  height: 100%;
  background: none;
  border: 0;
  @media (max-width: 700px) {
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
        display: none;
      }
  }
`;

const UserMenu = styled(Menu)`
  background: #7C7D7D;
  margin: 0;
  padding: 0;
`;

const UserMenuItem = styled(Menu.Item)`
  color: white;
  background: #7C7D7D;
  position: 0;
  padding-right: 5px;
  border-bottom: 1px solid white;
  border-right: 3px solid #7C7D7D;
  top: 0px;

  :last-child {
    border-bottom: 0px;
  }

  a {
  color: white
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  :hover {
    color: #e11f2d;
    border-right: 3px solid red;
    a {
      color: #e11f2d;
    }
    svg {
      fill: #e11f2d;
    }
  }
`;

const Icon = styled.svg`
  height: 22px;
  width: 22px;
  fill: white;
`;

interface Props {
  userName?: string;
  onLogout: () => void;
  onHelp: () => void;
  title: string;
  logo: any;
  onUserSettings: () => void;
}

const handleMenuClick = (e: any) => {
  console.log('click', e);
};

const Header = ({ onLogout, userName, title, logo, onUserSettings }: Props) => {
  const name = userName ? userName.split(/[@+]+/)[0] : undefined;
  const history = useHistory();
  const menu = (
  <UserMenu onClick={handleMenuClick}>
    <UserMenuItem key='1' onClick={() => {onUserSettings(); history.push('/settings/account-settings');}}>
        Settings
    </UserMenuItem>
    <UserMenuItem key='2'>
      <a href='https://aws.amazon.com/marketplace' target='_blank' rel='noopener noreferrer'>
        AWS Marketplace
        <Icon>
          <g transform='scale(0.8)'>
          <path d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' />
          </g>
        </Icon>
      </a>
    </UserMenuItem>
    <UserMenuItem key='3'>
      <a href='https://www.protagonist.io/' target='_blank' rel='noopener noreferrer'>
        Protagonist.io
        <Icon>
          <g transform='scale(0.8)'>
          <path d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' />
          </g>
        </Icon>
      </a>
    </UserMenuItem>
    <UserMenuItem key='4' onClick={onLogout}>
      Log out
    </UserMenuItem>
  </UserMenu>
);
  return (
    <Container>
      <LogoHead src={logoHead}/>
      <Logo src={logo} />
      <StyledSpan>{title}</StyledSpan>
      <SideContainer>
          <Dropdown trigger={['click']} overlay={menu}>
            <StyledButton type='primary'>
              <span>{name}</span><DownOutlined style={{fontSize: '13px'}} />
            </StyledButton>
          </Dropdown>
      </SideContainer>
    </Container>
  );
};

export default Header;
