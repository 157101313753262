import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import Settings from './Settings/Settings';
import DashboardManager from './Dashboard';
import NotFound from '../../components/NotFound';

const Container = styled.div`
  width: 99.5%;
  height: 100%;
  margin-left: 0.5%;
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: 700;
    margin: 0px;
    padding: 0px !important;
  }
  h4 {
    font-weight: 600;
    margin: 0px;
  }

  .ant-typography {
    color: #7d7e7e;
    padding-top: 15px;
    padding-bottom: 10px;
    article {
      font-size: 15px;
      font-weight: 400;
    }
  }
  .ant-input {
    color: black;
  }
  .ant-btn {
    color: white;
    margin: 1em;
    font-size: 12px;
    padding: 0.7em 2em;
    height: fit-content;
    background-color: #a5a5a4;
    :hover,
    :focus {
      background-color: #c5c5c4;
    }
  }
`;

const ContentNameContainer = styled.div`
  width: 100%;
  background-color: white;
  margin-bottom: 0.5%;
  padding: 0.3% 0.7%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  width: 100%;
  height: 100%
  overflow: auto;
  background-color: white;
`;

const ContentName = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #7c7d7d;
`;

const MainPageManager = () => {
  const [pageName, setPageName] = React.useState('Dashboard');

  return (
    <Container>
      <ContentNameContainer>
        <ContentName>{pageName.toUpperCase()}</ContentName>
      </ContentNameContainer>
      <ContentContainer>
        <Switch>
          <Route path='/settings' render={rest => (<Settings {...rest} setName={setPageName}/>)}/>
          <Route
            exact path='/dashboard/:categoryId/:type/:itemId' render={rest => (<DashboardManager {...rest} setName={setPageName}/>)}
          />
          <Route exact path='/login'>
              <Redirect to='/' />
          </Route>
          <Route path='/' component={NotFound} />
        </Switch>
      </ContentContainer>
    </Container>
  );
};

export default MainPageManager;
