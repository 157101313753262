import Constants from '../../constants';

export default (state = null, action) => {
  switch (action.type) {
    case Constants.AUTH_LOGIN_SUCCESS:
      return action.data.user;
    case Constants.AUTH_GET_USER_ID_FROM_LS_SUCCESS:
      return action.data;
    case Constants.AUTH_LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
};
