import { combineReducers } from 'redux';
import categories from './categoriesReducer';
import quicksight from './quicksightReducer';
import users from './usersReducer';
import myUser from './myUserReducer';
import addUser from './addUserReducer';
import updateUser from './updateUserReducer';

export default combineReducers({
  categories,
  quicksight,
  users,
  myUser,
  addUser,
  updateUser
});
