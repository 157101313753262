import Constants from '../../constants';

const defaultStatus = [''];

export default (state = defaultStatus, action) => {
  switch (action.type) {
    case Constants.SELECTED_KEY_UPDATE:
      return [action.key];
    case Constants.SELECTED_KEY_CLEAR:
      return defaultStatus;
    default:
      return state;
  }
};
