export default {
  // Login
  AUTH_LOGIN_REQUESTED: 'AUTH_LOGIN_REQUESTED',
  AUTH_LOGIN_LOADING: 'AUTH_LOGIN_LOADING',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_ERROR: 'AUTH_LOGIN_ERROR',
  AUTH_LOGIN_CLEAR: 'AUTH_LOGIN_CLEAR',

  // Complete New Password
  AUTH_COMPLETE_NEW_PASSWORD_REQUESTED: 'AUTH_COMPLETE_NEW_PASSWORD_REQUESTED',
  AUTH_COMPLETE_NEW_PASSWORD_LOADING: 'AUTH_COMPLETE_NEW_PASSWORD_LOADING',
  AUTH_COMPLETE_NEW_PASSWORD_SUCCESS: 'AUTH_COMPLETE_NEW_PASSWORD_SUCCESS',
  AUTH_COMPLETE_NEW_PASSWORD_ERROR: 'AUTH_COMPLETE_NEW_PASSWORD_ERROR',
  AUTH_COMPLETE_NEW_PASSWORD_CLEAR: 'AUTH_COMPLETE_NEW_PASSWORD_CLEAR',

  // Register
  AUTH_REGISTER_REQUESTED: 'AUTH_REGISTER_REQUESTED',
  AUTH_REGISTER_LOADING: 'AUTH_REGISTER_LOADING',
  AUTH_REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
  AUTH_REGISTER_ERROR: 'AUTH_REGISTER_ERROR',
  AUTH_REGISTER_CLEAR: 'AUTH_REGISTER_CLEAR',

  // Logout
  AUTH_LOGOUT_REQUESTED: 'AUTH_LOGOUT_REQUESTED',
  AUTH_LOGOUT_LOADING: 'AUTH_LOGOUT_LOADING',
  AUTH_LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
  AUTH_LOGOUT_ERROR: 'AUTH_LOGOUT_ERROR',
  AUTH_LOGOUT_CLEAR: 'AUTH_LOGOUT_CLEAR',

  // Reset Password
  AUTH_RESET_PASS_CODE_REQUEST_REQUESTED:
    'AUTH_RESET_PASS_CODE_REQUEST_REQUESTED',
  AUTH_RESET_PASS_CODE_REQUEST_LOADING: 'AUTH_RESET_PASS_CODE_REQUEST_LOADING',
  AUTH_RESET_PASS_CODE_REQUEST_SUCCESS: 'AUTH_RESET_PASS_CODE_REQUEST_SUCCESS',
  AUTH_RESET_PASS_CODE_REQUEST_ERROR: 'AUTH_RESET_PASS_CODE_REQUEST_ERROR',

  AUTH_RESET_PASS_SUBMIT_REQUESTED: 'AUTH_RESET_PASS_SUBMIT_REQUESTED',
  AUTH_RESET_PASS_SUBMIT_LOADING: 'AUTH_RESET_PASS_SUBMIT_LOADING',
  AUTH_RESET_PASS_SUBMIT_SUCCESS: 'AUTH_RESET_PASS_SUBMIT_SUCCESS',
  AUTH_RESET_PASS_SUBMIT_ERROR: 'AUTH_RESET_PASS_SUBMIT_ERROR',

  AUTH_RESET_PASS_CLEAR: 'AUTH_RESET_PASS_CLEAR',

  // Change Password
  AUTH_CHANGE_PASS_SUBMIT_REQUESTED: 'AUTH_CHANGE_PASS_SUBMIT_REQUESTED',
  AUTH_CHANGE_PASS_SUBMIT_LOADING: 'AUTH_CHANGE_PASS_SUBMIT_LOADING',
  AUTH_CHANGE_PASS_SUBMIT_SUCCESS: 'AUTH_CHANGE_PASS_SUBMIT_SUCCESS',
  AUTH_CHANGE_PASS_SUBMIT_ERROR: 'AUTH_CHANGE_PASS_SUBMIT_ERROR',

  // Local Storage
  AUTH_GET_TOKEN_FROM_LS_REQUESTED: 'AUTH_GET_TOKEN_FROM_LS_REQUESTED',
  AUTH_GET_TOKEN_FROM_LS_SUCCESS: 'AUTH_GET_TOKEN_FROM_LS_SUCCESS',
  AUTH_GET_TOKEN_FROM_LS_ERROR: 'AUTH_GET_TOKEN_FROM_LS_ERROR',
  AUTH_GET_USER_ID_FROM_LS_REQUESTED: 'AUTH_GET_USER_ID_FROM_LS_REQUESTED',
  AUTH_GET_USER_ID_FROM_LS_SUCCESS: 'AUTH_GET_USER_ID_FROM_LS_SUCCESS',
  AUTH_GET_USER_ID_FROM_LS_ERROR: 'AUTH_GET_USER_ID_FROM_LS_ERROR'
};
