import Constants from '../constants';

export default {
  getUsers: () => ({
    type: Constants.USERS_REQUESTED
  }),
  getMyUser: () => ({
    type: Constants.MY_USER_REQUESTED
  }),
  updateUser: (userId, data) => ({
    type: Constants.USER_UPDATE_REQUESTED,
    userId: userId,
    data: data
  }),
  updateUserClear: () => ({
    type: Constants.USER_UPDATE_CLEAR
  }),
  addUser: (data) => ({
    type: Constants.USER_ADD_REQUESTED,
    data: data
  }),
  addUserClear: () => ({
    type: Constants.USER_ADD_CLEAR
  }),
  clear: () => ({
    type: Constants.USERS_CLEAR
  })
};
