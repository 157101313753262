import Constants from '../constants';

export default {
  updateSelectedKey: (key) => ({
    type: Constants.SELECTED_KEY_UPDATE,
    key: key,
  }),
  clearSelectedKey: () => ({
    type: Constants.SELECTED_KEY_CLEAR,
  }),
  addOpenKeys: (key) => ({
    type: Constants.OPEN_KEYS_ADD,
    key: key,
  }),
  updateOpenKeys: (key) => ({
    type: Constants.OPEN_KEYS_UPDATE,
    key: key,
  }),
  clearOpenKeys: () => ({
    type: Constants.OPEN_KEYS_CLEAR,
  }),
};
