import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  color: black;
`;
const NotAvailable = () => {
  return <Message>Service not available</Message>;
};

export default NotAvailable;
