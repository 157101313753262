import Constants from '../constants';

export default {
  setToken: token => ({
    type: Constants.MARKETPLACE_SET_TOKEN,
    token
  }),
  clear: () => ({
    type: Constants.MARKETPLACE_CLEAR
  })
};
