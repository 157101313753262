import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_CONFIG_AUTH_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_CONFIG_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_WEB_CLIENT_ID
  }
});

const handleResponse = (response: any) => response.json().then((text: any) => {
  if (response.ok) {
    return { status: response.ok, body: text };
  }
  const errorObject = { status: response.ok, body: text };
  throw errorObject;
});

export const call = (
  route: string,
  method: string,
  body?: any,
  token?: string,
  tokenID?: string
) => {
  const headers: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
  if (token) headers['Authorization'] = `${token} ${tokenID}`;
  return fetch(process.env.REACT_APP_API_URL + route, {
    method,
    headers: new Headers(headers),
    body: body !== null ? JSON.stringify(body) : null
  })
    .then(handleResponse)
    .then(({status, body}) => ({ success: status, data: body }))
    .then(error => ({ success: false, ...error }))
    .catch(({status, body}) => ({ success: status, error: body }));
};

export default {
  Auth
};
