import Constants from '../../constants';

const defaultState = { status: false };

export default (state = defaultState, action) => {
  switch (action.type) {
    case Constants.AUTH_CHANGE_PASS_SUBMIT_SUCCESS:
      return { status: true };
    case Constants.AUTH_CHANGE_PASS_SUBMIT_LOADING:
      return { status: null };
    case Constants.AUTH_CHANGE_PASS_SUBMIT_ERROR:
      return { status: false, error: action.error };
    case Constants.AUTH_CHANGE_PASS_SUBMIT_REQUESTED:
    case Constants.AUTH_RESET_PASS_CLEAR:
      return defaultState;
    default:
      return state;
  }
};
