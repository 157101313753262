import { put, call } from 'redux-saga/effects';
import Constants from '../constants';
import API from '../api';

export function* getUsersSaga() {
  try {
    yield put({ type: Constants.USERS_LOADING });
    const {
      data: { token, tokenID }
    } = yield API.Auth.getToken();

    const res = yield call(API.Users.getUsers, token, tokenID);
    if (res.success && Array.isArray(res.data)) {
      const users = res.data;
      yield put({ type: Constants.USERS_SUCCESS, data: users });
    } else if (res.hasOwnProperty('error')) {
      throw Error(res.error);
    } else throw Error('Error with request');
  } catch (err) {
    yield put({
      type: Constants.USERS_ERROR,
      error: 'Service unavailable.'
    });
  }
}

export function* getMyUserSaga() {
  try {
    yield put({ type: Constants.MY_USER_LOADING });
    const {
      data: { token, tokenID }
    } = yield API.Auth.getToken();

    const res = yield call(API.Users.getMyUser, token, tokenID);
    if (res.success && res.data) {
      const user = res.data;
      yield put({ type: Constants.MY_USER_SUCCESS, data: user });
    } else if (res.hasOwnProperty('error')) {
      throw Error(res.error);
    } else throw Error('Error with request');
  } catch (err) {
    yield put({
      type: Constants.MY_USER_ERROR,
      error: 'Service unavailable.'
    });
  }
}

export function* updateUserSaga(params) {
  try {
    yield put({ type: Constants.USER_UPDATE_LOADING });
    const {
      data: { token, tokenID }
    } = yield API.Auth.getToken();
    const res = yield call(API.Users.updateUser, token, tokenID, params.userId, params.data);
    if (res.success && res.data) {
      const user = res.data;
      yield put({ type: Constants.USER_UPDATE_SUCCESS, data: user });
    } else if (res.hasOwnProperty('error')) {
      throw Error(res.error.detail);
    } else throw Error('Error with request');
  } catch (err) {
    yield put({
      type: Constants.USER_UPDATE_ERROR,
      error: err.message
    });
  }
}

export function* addUserSaga(params) {
  try {
    yield put({ type: Constants.USER_ADD_LOADING });
    const {
      data: { token, tokenID }
    } = yield API.Auth.getToken();
    const res = yield call(API.Users.addUser, token, tokenID, params.data);
    if (res.success && res.data) {
      const user = res.data;
      yield put({ type: Constants.USER_ADD_SUCCESS, data: user });
    } else if (res.hasOwnProperty('error')) {
      throw Error(res.error);
    } else throw Error('Error with request');
  } catch (err) {
    yield put({
      type: Constants.USER_ADD_ERROR,
      error: 'Service unavailable.'
    });
  }
}

export default {
  getUsersSaga,
  getMyUserSaga,
  updateUserSaga,
  addUserSaga
};
