import React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  width: 90%;
  padding-left: 20px;
`;

const Products = () => {
  return (
    <ItemContainer>
      Products
    </ItemContainer>
  );
};

export default Products;
