import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import ArticleDisplay from './ArticleDisplay';
import QuicksightDisplay from './QuicksightDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store';
import { isArticle } from '../../../store';
import { Spin } from 'antd';
import Actions from '../../../actions';
import NotFound from '../../../components/NotFound';

const Container = styled.div`
  height: 100%;
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props
  extends RouteComponentProps<{
    categoryId: string;
    itemId: string;
    type: string;
  }> {
  setName: (name: string) => void;
}

const DashboardManager = ({ match, setName }: Props) => {
  const dispatch = useDispatch();
  const categoriesState = useSelector((state: State) => state.Api.categories);

  const category = categoriesState.data
    .filter((x) => x.id === parseInt(match.params.categoryId))[0];

  const item = category?.contentList.filter((x) => {
    if(match.params.type === 'article') {
      return isArticle(x);
    }
    else if (match.params.type === 'quicksight') {
      return !isArticle(x);
    }
    else {
      return false;
    }
  }).filter(x => x.id.toString() === match.params.itemId)[0];

  React.useEffect(() => {
    if(item) {
      setName(item!.name);
      dispatch(Actions.Dashboard.addOpenKeys(`${category.id}`));
      dispatch(Actions.Dashboard.updateSelectedKey(`submenu-${category.id}-${match.params.type}-${item!.id}`));
    }
  }, [setName, item]); // eslint-disable-line

  return (
    <Container>
      {categoriesState.status ? (
        <>
          {item ? <>
            {isArticle(item) ? (
            <ArticleDisplay article={item} />
          ) : (
            <QuicksightDisplay quicksight={item} />
          )}
          </> : <NotFound />}
        </>
      ) : (
        <StyledSpin size='large' />
      )}
    </Container>
  );
};

export default DashboardManager;
