import Constants from '../../constants';

const defaultStatus = { status: false };

export default (state = defaultStatus, action) => {
  switch (action.type) {
    case Constants.AUTH_LOGIN_SUCCESS:
      return { status: true };
    case Constants.AUTH_LOGIN_LOADING:
      return { status: null };
    case Constants.AUTH_LOGIN_ERROR:
      if(action.data) {
        return { status: false, error: action.error, data: action.data };
      }
      return { status: false, error: action.error };
    case Constants.AUTH_LOGIN_REQUESTED:
    case Constants.AUTH_LOGOUT_SUCCESS:
    case Constants.AUTH_LOGIN_CLEAR:
      return defaultStatus;
    default:
      return state;
  }
};
