import React from 'react';
import styled from 'styled-components';
import { Modal, Typography, Alert, Form, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store';
import Actions from '../../../actions';

interface Props {
  selectedUser?: {
    email?: string;
    id?: number;
    is_enable?: boolean;
    role?: number;
  };
  visible: boolean;
  closeModal: () => void;
}

const { Title } = Typography;

const CustomTitle = styled(Title)`
  font-weight: 400 !important;
`;

const CustomModal = styled(Modal)`
  .ant-btn {
    color: #ff3333;
    > span {
      text-decoration: underline;
    }
    :hover,
    :focus {
      color: #ff5753;
    }
  }
  .ant-btn-primary {
    color: white;
    background-color: #9ea9a7;
    > span {
      text-decoration: none;
    }
    :hover,
    :focus {
      background-color: #c5c5c4;
    }
  }
  .ant-modal-footer {
    padding: 15px 20px;
  }
`;

const ListTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 300;
  display: inline;
`;

const EditUserModal = ({
  selectedUser,
  visible,
  closeModal,
}: Props) => {
  const dispatch = useDispatch();
  const updateUserState = useSelector((state: State) => state.Api.updateUser);
  const loading = updateUserState.status === null;
  const [form] = Form.useForm();

  const handleEditUser = (data: {role: number;}) => {
    dispatch(Actions.Users.updateUser(selectedUser?.id, data));
  };

  React.useEffect(() => {
    form.resetFields();
  }, [selectedUser, form]);


  const radioStyle = {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: '1.5em'
  };

  return (
    <CustomModal
      title='Edit User'
      visible={visible}
      onCancel={closeModal}
      okText='Submit'
      okButtonProps={{form:'user-edit-form', htmlType: 'submit'}}
      confirmLoading={loading}
    >
      <CustomTitle level={5}>
        You are editing user {selectedUser?.email}
      </CustomTitle>
      <Form
        form={form}
        onFinish={handleEditUser}
        style={{paddingTop: '0.5em'}}
        id='user-edit-form'
        layout='vertical'
        initialValues={{role: selectedUser?.role}}
      >
        <Form.Item
        name='role'>
          <Radio.Group>
            <Radio style={radioStyle} value={10}>
              <div>
                <CustomTitle level={5}>Admin</CustomTitle>
                <ListTypography>Allows user to have full permissions over account, including managing users</ListTypography>
              </div>
            </Radio>
            <Radio style={radioStyle} value={5}>
              <div>
                <CustomTitle level={5}>Viewer</CustomTitle>
                <ListTypography>Allows a user to view dashboard in the account</ListTypography>
              </div>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      {updateUserState.status
        ?
        <Alert message='User permissions was changed successfully!' type='success'/>
        :
        <div>
          {updateUserState.error && (
            <Alert message={updateUserState.error} type='error'/>
          )}
        </div>}
    </CustomModal>
  );
};

export default EditUserModal;
