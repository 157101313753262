import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, Spin, Modal, Button } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import { isArticle, State } from '../../store';
import Actions from '../../actions';
import Header from './Header';
import SupportDisplay from './SupportDisplay';
import MainPageManager from './MainPageManager';

import logo from '../../Images/Protagonist Logo Text_White.svg';
import logoHead from '../../Images/Protagonist Logo Head_White.svg';


const { SubMenu } = Menu;

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #7C7D7D;
`;

const LogoHead = styled.img`
  margin: 0% 32%;
  @media (max-width: 700px) {
    display: none;
  }
`;

const LogoHeadText = styled.div`
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 700;
  font-size: 12px;
  padding-bottom: 12%;
  line-height: 1.4em;
  text-align: center;
  @media (max-width: 700px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto
  height: 93vh;
  max-height: 93vh;
`;

const MenuLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 12vw;
  max-width: 20vw;
  height: 100%;
`;

const ContentLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: #d7d4d4;
`;

const CategoryMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7C7D7D;
  width: 100%;
  border: 0;
`;
const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  align-items: center;
  min-width: 12vw;
  max-width: 20vw;
  justify-content: space-between;
`;

const RightPanel = styled.div`
  display: inline-block;
  background-color: #d7d4d4;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const StyledSubMenu = styled(SubMenu)`
  color: white;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 15px;

  .ant-menu-submenu-title{
    width: 100%;
    margin: 0px;
    padding: 12%;
    display: flex;
    align-items: center;
  }

  .ant-menu-item {
    width: 100%;
  }

  div {
    :hover {
      color: white;
      font-weight: 600;
      background: #979494;
    }
    i {
      :before, :after {
      background: white !important;
      }
    }
  }

  .ant-menu-submenu-title[aria-expanded=true] {
    font-weight: 600;
    border-right: 3px solid #e11f2d;
    background: #A5A5A5;
  }

    .ant-menu-submenu-title[aria-expanded=false] {
    border-right: 3px solid transparent;
  }
  @media (max-width: 700px) {
    i {
        display: none;
    }
  }
`;

const SubMenuItem = styled(Menu.Item)`
  background: #858485;
  border-bottom: 1px solid #b5b5b5;
  padding: 20%;
  padding-right: 3px;

  &.ant-menu-item {
    color: white;
  }

  &.ant-menu-item-selected {
    background-color: #e5e5e5 !important;
    color: #e11f2d;
  }

  :first-child {
    border-top: 1px solid #b5b5b5;
  }
  :hover {
    background-color: #b7b4b4;
  }
`;

const SupportButton = styled(Button)`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 12%;
  margin: 1%;
  background-color: #898a8a;
  border: 0;
  font-size: 16px;
`;


const MainPage = ({ history }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const categoriesState = useSelector((state: State) => state.Api.categories);
  const user = useSelector((state: State) => state.Auth.user);
  const [supportDisplay, setSupportDisplay] = React.useState(false);
  const [errorDisplayed, setErrorDisplayed] = React.useState(false);
  const staticData = categoriesState.data;
  const selectedKey = useSelector((state: State) => state.Dashboard.selectedKey);
  const openKeys = useSelector((state: State) => state.Dashboard.openKeys);

  const onLogout = async() => {
    await dispatch(Actions.Auth.logout());
    history.push('/login');
  };

  const onHelp = () => {
    setSupportDisplay(true);
  };

  React.useEffect(() => {
    if (
      !categoriesState.status &&
      categoriesState.error &&
      !errorDisplayed
    ) {
      setErrorDisplayed(true);
      Modal.error({
        title: 'Something went wrong.',
        content: categoriesState.error,
        onOk() {
          dispatch(Actions.Auth.logout());
        },
        zIndex: 1040
      });
    }
  }, [categoriesState, errorDisplayed, dispatch, history]);

  const setUserSettings = () => {
    dispatch(Actions.Dashboard.clearOpenKeys());
    dispatch(Actions.Dashboard.clearSelectedKey());
  };

  return (
    <Page>
      <Header
        onHelp={onHelp}
        onLogout={onLogout}
        userName={user ? user.attributes?.email : ''}
        title='CONTENT OPTIMIZATION SOLUTION'
        logo={logo}
        onUserSettings={setUserSettings}
      />
      <ContentContainer>
        {staticData ? (
          <>
              <LeftPanel>
              <CategoryMenu
                mode='inline'
                openKeys={openKeys}
                selectedKeys={selectedKey}
              >
                  <LogoHead src={logoHead}/>
                  <LogoHeadText>NARRATIVE ANALYTICS</LogoHeadText>
                  {staticData &&
                    staticData.map(
                      (category) =>
                        (<StyledSubMenu onTitleClick={(e) => dispatch(Actions.Dashboard.updateOpenKeys(e.key.toString()))}
                                        key={`${category.id}`}
                                        title={category.name}
                        >
                              {category.contentList.map((item) => {
                                const type = isArticle(item) ? 'article' : 'quicksight';
                                return <SubMenuItem
                                  key={`submenu-${category.id}-${type}-${item.id}`}
                                  onClick={() => {
                                    history.push(`/dashboard/${category.id}/${type}/${item.id}`);
                                  }}
                                  style={{ margin: '0' }}
                                >
                                  <span>{item.name}</span>
                                </SubMenuItem>;
                              })}
                        </StyledSubMenu>)
                    )}
              </CategoryMenu>
                <SupportButton type='primary' onClick={onHelp} icon={<QuestionCircleFilled />}>
                    Support
                </SupportButton>
                </LeftPanel>
            <RightPanel>
            <MainPageManager />
            </RightPanel>
          </>
        ) : (
          <>
            <MenuLoading>
              <Spin size='large' />
            </MenuLoading>
            <ContentLoading>
              <Spin size='large' />
            </ContentLoading>
          </>
        )}
        <SupportDisplay
          title='Get help from Protagonist'
          isVisible={supportDisplay}
          onCancel={() => setSupportDisplay(false)}
        />
      </ContentContainer>
    </Page>
  );
};

export default withRouter(MainPage);
