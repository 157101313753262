import Constants from '../../constants';

const defaultStatus = { status: false };

export default (state = defaultStatus, action) => {
  switch (action.type) {
    case Constants.USER_ADD_SUCCESS:
      return { status: true, data: action.data };
    case Constants.USER_ADD_LOADING:
      return { ...state, status: null };
    case Constants.USER_ADD_ERROR:
      return { status: false, error: action.error };
    case Constants.USER_ADD_REQUESTED:
      return { ...state, status: null };
    case Constants.USER_ADD_CLEAR:
      return defaultStatus;
    default:
      return state;
  }
};
