import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface CustomRouteProps extends RouteProps {
  component: any;
  isSignedIn?: boolean;
}

const PrivateRoute = (props: CustomRouteProps) => {
  const { component: Component, isSignedIn, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps =>
        isSignedIn ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  );
};

const PublicRoute = (props: CustomRouteProps) => {
  const { component: Component, isSignedIn, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps =>
        !isSignedIn ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  );
};

export default {
  PrivateRoute,
  PublicRoute
};
