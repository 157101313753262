import { fork } from 'redux-saga/effects';
import Watchers from './watcher';

function* rootSaga() {
  for (const key in Watchers) {
    yield fork(Watchers[key]);
  }
}

export default rootSaga;
