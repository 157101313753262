import React from 'react';
import styled from 'styled-components';
import { StopOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const Message = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Block = styled.div`
  display: flex;
  align-items: baseline
`;

const CustomIcon = styled(StopOutlined)`
  font-size: 2rem;
  padding: 0.2em;
  color: grey;
`;

const { Title } = Typography;

const CustomTitle = styled(Title)`
  color: grey !important;
`;

const NotFound = () => {
  return <Message>
    <Block>
      <CustomIcon />
      <CustomTitle>404 - Not found</CustomTitle>
    </Block>
  </Message>;
};

export default NotFound;
