import React from 'react';
import styled from 'styled-components';
import { Modal, Spin } from 'antd';

const ItemContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  padding-left: 15px;
  overflow: auto;
  padding-top: 20px;
`;

const Container = styled.div`
  display: flex;
  height: 600px;
  justify-content: center;
  align-items: center;
`;

const StyledSpin = styled(Spin)`
  display: absolute;
  height: 100px;
  background-color: red;
`;

interface Props {
  title: string;
  isVisible: boolean;
  onCancel: () => void;
}

const SupportDisplay = ({ isVisible, onCancel, title }: Props) => {
  const formRef = React.useRef<any>();
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  React.useEffect(() => {
    // SUPPORT FORM
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_SUPPORT_FORM_SCRIPT_SRC!;
    script.defer = true;
    script.addEventListener('load', () => {
      (window as any).hbspt.forms.create({
        portalId: process.env.REACT_APP_SUPPORT_FORM_PORTAL_ID,
        formId: process.env.REACT_APP_SUPPORT_FORM_FORM_ID,
        target: '#support-form'
      });
      setScriptLoaded(true);
    });
    document.head.appendChild(script);
  }, []);

  return (
    <>
      <Modal
        title={title}
        onCancel={onCancel}
        visible={isVisible}
        footer={null}
        closable
        forceRender={true}
      >
        <Container>
          <ItemContainer ref={formRef} id='support-form' />
          {!scriptLoaded ? <StyledSpin></StyledSpin> : <></>}
        </Container>
      </Modal>
    </>
  );
};

export default SupportDisplay;
