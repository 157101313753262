import React from 'react';
import { Provider } from 'react-redux';
import configStore from './store';
import * as Sentry from '@sentry/browser';

import './App.less';
import AppRouter from './AppRouter';

const store = configStore();

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

export default App;
