import Constants from '../constants';

export default {
  login: (email, password) => ({
    type: Constants.AUTH_LOGIN_REQUESTED,
    email,
    password
  }),
  completeNewPassword: (email, oldPassword, newPassword) => ({
    type: Constants.AUTH_COMPLETE_NEW_PASSWORD_REQUESTED,
    email,
    oldPassword,
    newPassword,
  }),
  register: (email, password, token) => ({
    type: Constants.AUTH_REGISTER_REQUESTED,
    email,
    password,
    token
  }),
  logout: () => ({ type: Constants.AUTH_LOGOUT_REQUESTED }),
  resetPass: {
    request: email => ({
      type: Constants.AUTH_RESET_PASS_CODE_REQUEST_REQUESTED,
      email
    }),
    restore: (email, code, password) => ({
      type: Constants.AUTH_RESET_PASS_SUBMIT_REQUESTED,
      email,
      code,
      password
    }),
    change: (user, oldPassword, newPassword) => ({
      type: Constants.AUTH_CHANGE_PASS_SUBMIT_REQUESTED,
      user,
      oldPassword,
      newPassword
    })
  },
  clear: {
    login: () => ({ type: Constants.AUTH_LOGIN_CLEAR }),
    register: () => ({ type: Constants.AUTH_REGISTER_CLEAR }),
    logout: () => ({ type: Constants.AUTH_LOGOUT_CLEAR }),
    resetPass: () => ({ type: Constants.AUTH_RESET_PASS_CLEAR })
  },
  loadTokenFromAmplify: () => ({
    type: Constants.AUTH_GET_TOKEN_FROM_LS_REQUESTED
  }),
  loadUserFromAmplify: () => ({
    type: Constants.AUTH_GET_USER_ID_FROM_LS_REQUESTED
  })
};
