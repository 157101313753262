import React, { useEffect } from 'react';
import { State, Quicksight } from '../../../store';
import IFrame from 'react-iframe';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../actions';
import { Spin } from 'antd';

interface Props {
  quicksight?: Quicksight;
}

const ContentLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledIframe = styled(IFrame)`
  width: 100%;
  height: 100%;
  border: 0;
  color: black;
`;

const QuicksightDisplay = ({ quicksight }: Props) => {
  const dispatch = useDispatch();
  const dashboardUrl = useSelector(
    (state: State) => state.Api.quicksight.dashboardUrl
  );

  useEffect(() => {
    dispatch(Actions.Quicksight.getQuicksight(quicksight));
     // eslint-disable-next-line
  }, [quicksight]);

  if (dashboardUrl) {
    return (
      <StyledIframe
        width='100%'
        height='100%'
        scrolling='no'
        url={dashboardUrl}
      />
    );
  } else
    return (
      <ContentLoading>
        <Spin size='large' />
      </ContentLoading>
    );
};

export default QuicksightDisplay;
