import React from 'react';
import styled from 'styled-components';
import Condition from './Condition';

interface Props {
  password?: string;
  passwordValidation?: string;
}

const Container = styled.div`
  width: 200px;
`;

const stringContains = (string: string, regexp: RegExp) => {
  return string.match(regexp) !== null;
};

const PasswordRequirement = ({ password }: Props) => {
  const checkedPassword = password ? password : ''; // to avoid undefined ternary on each test

  const length = checkedPassword.length >= 8;
  const lower = stringContains(checkedPassword, /(?=.*[a-z])/);
  const upper = stringContains(checkedPassword, /(?=.*[A-Z])/);
  const digit = stringContains(checkedPassword, /(?=.*[0-9])/);
  const special = stringContains(
    checkedPassword,
    /(?=.*[\^$*.[\]{}()?"!@#%&/\\,>< ':;|_~`-])/
  );

  return (
    <Container>
      <Condition status={length} text='Be at least 8 characters long.' />
      <Condition status={digit} text='Have at least 1 number.' />
      <Condition status={lower} text='Have at least 1 lowercase letter.' />
      <Condition status={upper} text='Have at least 1 uppercase letter.' />
      <Condition status={special} text='Have at least 1 special character.' />
    </Container>
  );
};

export default PasswordRequirement;
