import Constants from '../../constants';

const defaultStatus = [''];

export default (state = defaultStatus, action) => {
  switch (action.type) {
    case Constants.OPEN_KEYS_ADD:
      if (state.includes(action.key)) {
        return state;
      }
      return [...state, action.key];
    case Constants.OPEN_KEYS_UPDATE:
      if (state.includes(action.key)) {
        return state.filter(x => x !== action.key);
      }
      return [...state, action.key];
    case Constants.OPEN_KEYS_CLEAR:
      return defaultStatus;
    default:
      return state;
  }
};
