export default {
  Auth: {
    login: { status: false },
    register: { status: false },
    token: null,
    user: null,
    reset_password: {
      codeRequest: { status: false },
      passSubmit: { status: false }
    }
  },
  Api: {
    categories: { status: false },
    users: { status: false }
  },
  Marketplace: {
    token: null
  }
};
