import Constants from '../../constants';

export default (state = {success: undefined}, action) => {
  switch (action.type) {
    case Constants.AUTH_LOGIN_SUCCESS:
      return action.data.token;
    case Constants.AUTH_GET_TOKEN_FROM_LS_SUCCESS:
      return action.token;
    case Constants.AUTH_LOGOUT_SUCCESS:
      return {success: false};
    case Constants.AUTH_GET_TOKEN_FROM_LS_ERROR:
      return {success: false, data: {error: action.error}};
    case Constants.AUTH_GET_TOKEN_FROM_LS_REQUESTED:
      return state
    default:
      return state;
  }
};
