import { combineReducers } from 'redux';
import Auth from './auth';
import Api from './api';
import Marketplace from './marketplace';
import Dashboard from './dashboard';

export default combineReducers({
  Auth,
  Api,
  Marketplace,
  Dashboard,
});
