import Constants from '../../constants';

const defaultStatus: string | null = null;

export default (state = defaultStatus, action: any) => {
  switch (action.type) {
    case Constants.MARKETPLACE_SET_TOKEN:
      return action.token;
    case Constants.MARKETPLACE_CLEAR:
      return defaultStatus;
    default:
      return state;
  }
};
