import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Alert, Button, Form, Input, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { isFetching } from '../misc/StateInfos';
import { State } from '../store';
import Actions from '../actions';

import protagonistLogo from '../Images/Protagonist Logo_Horizontal.svg';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ResetPasswordSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: space-evenly;
  justify-content: space-between;
  height: 100%;
  background-color: #ffffff;
`;

const Logo = styled.img`
  padding-top: 2%;
  padding-bottom: 2%;
  width: 74%;
  height: auto;
`;

const WelcomeText = styled.text`
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-weight: 600;
  font-size: 140%;
  padding-top: 10%;
`;

const CustomForm = styled(Form)`
  margin-top: 2%;
  padding-bottom: 10%
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CustomInputPassword = styled(Input.Password)`
  width: 100%;
  border: 0px solid black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

const CustomInput = styled(Input)`
  width: 100%;
  border: 0px solid black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

const BackButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  margin: 5px 0px;
  padding 5px 0px;
  box-shadow: 0px 0px 0px black;
`;

const NewPassword = ({ location, history }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const newPasswordState = useSelector(
    (state: State) => state.Auth.complete_new_password
  );
  const user: any = location?.state;

  const onSubmitPasswordChange = (data: any) => {
    const email = user.email;
    const oldPassword = user.password;
    dispatch(
      Actions.Auth.completeNewPassword(email, oldPassword, data.newPassword)
    );
  };

  return (
    <ResetPasswordSection>
      <LogoContainer>
        <BackButton
          onClick={() => history.push('/login')}
          icon={<ArrowLeftOutlined />}
        >
          Back to login
        </BackButton>
        <Logo src={protagonistLogo} />
        <WelcomeText>Change Password</WelcomeText>
      </LogoContainer>
      <FormContainer>
        <CustomForm onFinish={onSubmitPasswordChange} layout='vertical'>
          <Form.Item
            name='email'
            initialValue={user?.email}
            label={<Typography>Email Address</Typography>}
          >
            <CustomInput disabled />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input your new Password!' },
              {
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                message: 'Wrong format!',
              },
            ]}
            label={<Typography>New Password</Typography>}
            name='newPassword'
          >
            <CustomInputPassword />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please confirm your new Password!' },
              {
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                message: 'Wrong format!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!'
                  );
                },
              }),
            ]}
            label={<Typography>Confirm New Password</Typography>}
            name='confirmNewPassword'
          >
            <CustomInputPassword />
          </Form.Item>
          {newPasswordState.status ? (
            <Alert
              message='Password was changed successfully!'
              type='success'
            />
          ) : (
            <div>
              {newPasswordState.error && (
                <Alert message={newPasswordState.error} type='error' />
              )}
            </div>
          )}
          <Button
            type='primary'
            htmlType='submit'
            loading={isFetching(newPasswordState)}
          >
            Update
          </Button>
        </CustomForm>
      </FormContainer>
    </ResetPasswordSection>
  );
};

export default withRouter(NewPassword);
