import React, { useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';

import Routes from './components/Routes';
import MainPage from './pages/MainPage';
import { useSelector, useDispatch } from 'react-redux';
import { isArticle, State } from './store';
import Actions from './actions';
import { Spin } from 'antd';
import styled from 'styled-components';
import NotAvailable from './components/NotAvailable';
import PublicPage from './pages/PublicPage';
import NotFound from './components/NotFound';

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const AppRouter = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: State) => state.Auth.token);
  const categoriesState = useSelector((state: State) => state.Api.categories);
  const [firstItemPath, setFirstItemPath] = React.useState('/dashboard');
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  let signIn: boolean | undefined = undefined;

  if (token) {
    signIn = token.success !== undefined ? token.success : !!token;
  }
  const loading = token === null;

  React.useEffect(() => {
    if (categoriesState.status) {
      const category = categoriesState.data[0];
      const item = category.contentList[0];
      const type = isArticle(item) ? 'article' : 'quicksight';

      setFirstItemPath(`/dashboard/${category.id}/${type}/${item.id}`);
      setCategoriesLoading(false);
    }
  }, [categoriesState, token]);

  React.useEffect(() => {
    dispatch(Actions.Auth.loadUserFromAmplify());
    dispatch(Actions.Auth.loadTokenFromAmplify());
    if (signIn) {
      dispatch(Actions.Categories.getCategories());
    }
  }, [dispatch, signIn]);

  return (
    <>
      {loading || (signIn && categoriesLoading) ? (
        <>
          {categoriesLoading ? <StyledSpin size='large' /> : <NotAvailable />}
        </>
      ) : (
        <Router>
          <Switch>
            {!signIn ? (
              <>
                <Routes.PublicRoute
                  isSignedIn={signIn}
                  path='/'
                  component={PublicPage}
                />
              </>
            ) : (
              <>
                <Routes.PrivateRoute
                  isSignedIn={signIn}
                  exact
                  path='/'
                  component={MainPage}
                >
                  <Redirect to={firstItemPath} />
                </Routes.PrivateRoute>
                <Routes.PrivateRoute
                  isSignedIn={signIn}
                  path='/'
                  component={MainPage}
                />
              </>
            )}
            <Route exact path='/404' component={NotFound} />
          </Switch>
        </Router>
      )}
    </>
  );
};

export default AppRouter;
