import Constants from '../../constants';

const defaultStatus = { status: false };

const defaultState = {
  codeRequest: defaultStatus,
  passSubmit: defaultStatus
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Constants.AUTH_RESET_PASS_CODE_REQUEST_SUCCESS:
      return {
        ...state,
        codeRequest: { status: true }
      };
    case Constants.AUTH_RESET_PASS_CODE_REQUEST_LOADING:
      return {
        ...state,
        codeRequest: { status: null }
      };
    case Constants.AUTH_RESET_PASS_CODE_REQUEST_ERROR:
      return {
        ...state,
        codeRequest: { status: false, error: action.error }
      };
    case Constants.AUTH_RESET_PASS_SUBMIT_SUCCESS:
      return {
        ...state,
        passSubmit: { status: true }
      };
    case Constants.AUTH_RESET_PASS_SUBMIT_LOADING:
      return {
        ...state,
        passSubmit: { status: null }
      };
    case Constants.AUTH_RESET_PASS_SUBMIT_ERROR:
      return {
        ...state,
        passSubmit: { status: false, error: action.error }
      };

    case Constants.AUTH_RESET_PASS_CODE_REQUEST_REQUESTED:
    case Constants.AUTH_RESET_PASS_SUBMIT_REQUESTED:
    case Constants.AUTH_RESET_PASS_CLEAR:
      return defaultState;
    default:
      return state;
  }
};
