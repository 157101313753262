import Constants from '../../constants';

const defaultStatus = { status: false };

export default (state = defaultStatus, action) => {
  switch (action.type) {
    case Constants.QUICKSIGHT_SUCCESS:
      return { status: true, dashboardUrl: action.data };
    case Constants.QUICKSIGHT_LOADING:
      return { status: null };
    case Constants.QUICKSIGHT_ERROR:
      return { status: false, error: action.error };
    case Constants.QUICKSIGHT_REQUESTED:
    case Constants.QUICKSIGHT_CLEAR:
      return defaultStatus;
    default:
      return state;
  }
};
