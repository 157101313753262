import Constants from '../constants';

export default {
  getQuicksight: quicksight => ({
    type: Constants.QUICKSIGHT_REQUESTED,
    quicksight
  }),
  clear: () => ({
    type: Constants.QUICKSIGHT_CLEAR
  })
};
