import React, { useState } from 'react';
import { Button, Alert, Modal, Popover } from 'antd';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Actions from '../actions';
import logo from '../protagonist-logo512x512.png';
import { State } from '../store';
import { FormField, FormPasswordField } from '../components/FormFields';
import { isFetching } from '../misc/StateInfos';
import PasswordRequirement from './MainPage/PasswordRequirement';

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 600px;
  width: 400px;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
`;

const Logo = styled.img`
  width: 260px;
  height: auto;
`;

const CustomForm = styled.form`
  min-height: 300px;
  width: 260px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  > span {
    margin-bottom: 10px;
  }

  > button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

type FormValues = {
  email?: string;
  password?: string;
  passwordValidation?: string;
};

const validateForm = (values: FormValues) => {
  const errors: FormValues = {
    email: undefined,
    password: undefined,
    passwordValidation: undefined
  };
  if (!values.email) {
    errors.email = 'Email required.';
  }
  if (!values.password) {
    errors.password = 'Password required.';
  }
  if (!values.passwordValidation) {
    errors.passwordValidation = 'Password validation required.';
  }
  if (
    values.password &&
    values.passwordValidation &&
    values.password !== values.passwordValidation
  ) {
    errors.passwordValidation = 'Passwords must match.';
  }
  return errors;
};

const Register = ({ history }: RouteComponentProps) => {
  const [confirmation, setConfirmation] = useState(false);
  const dispatch = useDispatch();
  const registerState = useSelector((state: State) => state.Auth.register);
  const token = useSelector((state: State) => state.Marketplace.token);

  const onSubmit = (values: FormValues) => {
    dispatch(Actions.Auth.register(values.email, values.password, token));
  };

  React.useEffect(() => {
    if (!confirmation && registerState.status) {
      setConfirmation(true);
      Modal.success({
        title: 'Account Created !',
        content: 'You should have received a validation link in your mailbox.',
        onOk() {
          history.push('/login');
        },
        zIndex: 1040
      });
    }
  }, [confirmation, registerState.status, history]);

  return (
    <Page>
      <Container>
        <Logo src={logo} />
        <Form onSubmit={onSubmit} validate={validateForm}>
          {({ handleSubmit, submitting, pristine, values }) => {
            return (
              <CustomForm onSubmit={handleSubmit} className='login-form'>
                <FormField
                  name='email'
                  placeholder='Enter your email.'
                  iconType='user'
                ></FormField>
                <Popover
                  placement='right'
                  content={
                    <PasswordRequirement
                      password={values.password}
                      passwordValidation={values.passwordValidation}
                    />
                  }
                  trigger='focus'
                  title='Password must:'
                  visible={!!values.password}
                >
                  <FormPasswordField
                    name='password'
                    placeholder='Enter your password.'
                    iconType='lock'
                  ></FormPasswordField>
                </Popover>
                <FormPasswordField
                  name='passwordValidation'
                  placeholder='Enter your password again.'
                  iconType='lock'
                ></FormPasswordField>
                {registerState.error && (
                  <Alert message={registerState.error} type='error' />
                )}
                <Button
                  type='primary'
                  icon=''
                  htmlType='submit'
                  loading={isFetching(registerState)}
                >
                  Register
                </Button>
                <Button type='ghost' icon='' onClick={() => history.push('/')}>
                  Back to log in
                </Button>
              </CustomForm>
            );
          }}
        </Form>
      </Container>
    </Page>
  );
};

export default withRouter(Register);
