import React from 'react';
import styled from 'styled-components';
import { Form, Modal, Typography, Input, Radio, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store';
import Actions from '../../../actions';

interface Props {
  visible: boolean;
  closeModal?: () => void;
}

const CustomInput = styled(Input)`
  color: black;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 1px #d7d7d7;
`;

const { Title } = Typography;

const CustomTitle = styled(Title)`
  font-weight: 400 !important;
`;

const ListTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 300;
  display: inline;
`;

const CustomModal = styled(Modal)`
  .ant-btn {
    color: #ff3333;
    > span {
      text-decoration: underline;
    }
    :hover, :focus {
      color: #ff5753;
    }
  }
  .ant-btn-primary {
    color: white;
    background-color: #9ea9a7;
    > span {
      text-decoration: none;
    }
    :hover, :focus {
      background-color: #c5c5c4;
    }
  }
  .ant-modal-footer {
    padding: 15px 20px;
  }
`;

const InviteUserModal = ({ visible, closeModal }: Props) => {
  const dispatch = useDispatch();
  const addUserState = useSelector((state: State) => state.Api.addUser);
  const addUserLoading = addUserState.status === null;

  const handleInviteUser = (data: object) => {
    dispatch(Actions.Users.addUser(data));
  };

  const radioStyle = {
      display: 'flex',
      alignItems: 'baseline',
      paddingTop: '1.5em'
    };
  return (
    <CustomModal
      title='Invite a User'
      visible={visible}
      onCancel={closeModal}
      okButtonProps={{form:'category-editor-form', htmlType: 'submit'}}
      okText='Invite User'
      confirmLoading={addUserLoading}
    >
      <CustomTitle level={4}>Invite new users in your company to view your Dashboard</CustomTitle>
      <Form onFinish={handleInviteUser} style={{paddingTop: '0.5em'}} id='category-editor-form' layout='vertical'>
        <Form.Item
          rules={[{ required: true, message: 'Please input mail!' }, { type: 'email', message: 'This is not valid mail!'}]}
          name='email' label={<Typography>Email Address</Typography>}>
          <CustomInput placeholder='example@abc.com' />
        </Form.Item>
        <CustomTitle style={{paddingTop: '0.5em'}} level={4}>Select User Type</CustomTitle>
        <Form.Item
          rules={[{ required: true, message: 'Please choose role!' }]}
          name='role'>
          <Radio.Group >
            <Radio style={radioStyle} value={10}>
              <div>
                <CustomTitle level={5}>Admin</CustomTitle>
                <ListTypography>Allows user to have full permissions over account, including managing users</ListTypography>
              </div>
            </Radio>
            <Radio style={radioStyle} value={5}>
              <div>
                <CustomTitle level={5}>Viewer</CustomTitle>
                <ListTypography>Allows a user to view dashboard in the account</ListTypography>
              </div>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      {addUserState.status
        ?
        <Alert message='User was invited successfully!' type='success'/>
        :
        <div>
          {addUserState.error && (
            <Alert message={addUserState.error} type='error'/>
          )}
        </div>}
    </CustomModal>
  );
};

export default InviteUserModal;
