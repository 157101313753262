import { call } from './misc';

export const getUsers = (token: string, tokenId: string) =>
  call('/customers/users/', 'GET', null, token, tokenId);

export const getMyUser = (token: string, tokenId: string) =>
  call('/customers/users/me/', 'GET', null, token, tokenId);

export const updateUser = (token: string, tokenId: string, userId: number | undefined, data: object) => {
  if (userId) {
    return call(`/customers/users/${userId}/`, 'PUT', data, token, tokenId);
  }
  return call(`/customers/users/me/`, 'PUT', data, token, tokenId);
};

export const addUser = (token: string, tokenId: string, data: object) =>
  call('/customers/users/add/', 'POST', data, token, tokenId);

export default {
  getUsers,
  getMyUser,
  updateUser,
  addUser
};
