import React from 'react';
import { Article } from '../../../store';
import styled from 'styled-components';

interface Props {
  article?: Article;
}

const ItemContainer = styled.div`
  padding: 15px;
`;

const ArticleDisplay = ({ article }: Props) => {
  return (
    <ItemContainer
      dangerouslySetInnerHTML={{
        __html: article ? article.content : ''
      }}
    />
  );
};

export default ArticleDisplay;
