import React from 'react';
import styled from 'styled-components';
import { Icon } from '@ant-design/compatible';

interface Props {
  text: string;
  status: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  padding-top: 5px;
`;

const StyledIcon = styled(Icon)`
  width: 1em;
  height: 1em;
`;

const StyledSpan = styled.span`
  height: 100%;
  line-height: 1em;
  padding-left: 4px;
`;

const Condition = ({ text, status }: Props) => {
  const icon = status ? 'check-circle' : 'close-circle';
  const color = status ? '#52c41a' : '#ffa39e';

  return (
    <Container>
      <StyledIcon type={icon} theme='twoTone' twoToneColor={color} />
      <StyledSpan color={color}>{text}</StyledSpan>
    </Container>
  );
};

export default Condition;
