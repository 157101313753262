import React from 'react';
import { Button, Alert } from 'antd';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import Actions from '../actions';
import { State } from '../store';
import { FormField, FormPasswordField } from '../components/FormFields';
import { isFetching } from '../misc/StateInfos';

import protagonistLogo from '../Images/Protagonist Logo_Horizontal.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
`;

const Logo = styled.img`
  margin-bottom: 50px;
  width: 74%;
  height: auto;
`;

const WelcomeText = styled.text`
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-weight: 600;
  font-size: 1.4rem;
  @media (max-height: 400px) {
    display: none;
  }
`;

const ForgotPassword = styled.div`
  position: relative;
  padding-bottom: 10%;
  margin-bottom: 5%

  > button {
    font-size: 100%;
    font-family: 'Myriad Pro', Myriad, 'Liberation Sans', 'Nimbus Sans L',
      'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding-bottom: 10%;
    margin-bottom: 5%
    position: absolute;
    right: 0px;
  }
`;

const CustomForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 3%;
`;

type FormValues = {
  email?: string;
  password?: string;
};

const validateForm = (values: FormValues) => {
  const errors: FormValues = {
    email: undefined,
    password: undefined
  };
  if (!values.email) {
    errors.email = 'Email required.';
  }
  if (!values.password) {
    errors.password = 'Password required.';
  }

  return errors;
};

const Login = ({ history, location }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const loginState = useSelector((state: State) => state.Auth.login);
  const marketplaceState = useSelector((state: State) => state.Marketplace);
  const [userData, setUserData] = React.useState<{email: string | undefined; password: string | undefined} | undefined>(undefined);

  const onSubmit = (values: FormValues) => {
    setUserData({email: values.email, password: values.password});
    dispatch(Actions.Auth.login(values.email, values.password));
  };

  React.useEffect(() => {
    const search = location.search;

    const regResult = search.match(/[token=]+=([^&]+)/);
    if (!regResult || !regResult[1]) {
      return;
    }

    const token = regResult[1];
    if (token !== marketplaceState.token) {
      dispatch(Actions.Marketplace.setToken(token));
      history.push('/register');
    }
  }, [location, dispatch, marketplaceState.token]); // eslint-disable-line

  React.useEffect(() => {
    if (loginState.status) {
      history.push('/');
    }
    if (loginState.error === 'NEW_PASSWORD_REQUIRED' && userData){
      history.push({pathname: '/new-password', state: userData});
    }
  }, [loginState, history]); // eslint-disable-line

  React.useEffect(() => {
    dispatch(Actions.Auth.clear.login());
  }, []); // eslint-disable-line

  return (
        <Container>
          <LogoContainer>
          <Logo src={protagonistLogo} />
            <WelcomeText>Welcome to Protagonist</WelcomeText>
          </LogoContainer>
          <FormContainer>
          <Form onSubmit={onSubmit} validate={validateForm}>
            {({ handleSubmit, submitting, pristine, values }) => (
              <CustomForm onSubmit={handleSubmit} className='login-form'>
                <FormField name='email' placeholder='Email Address'/>
                <FormPasswordField
                  name='password'
                  placeholder='Password'
                />
                <ForgotPassword>
                  <Button
                    type='link'
                    icon=''
                    onClick={() => history.push('/reset-password')}
                  >
                    Forgot password?
                  </Button>
                </ForgotPassword>
                {loginState.error && (
                  <Alert message={loginState.error} type='error' />
                )}
                <Button
                  type='primary'
                  icon=''
                  loading={isFetching(loginState)}
                  htmlType='submit'
                >
                  Log in
                </Button>
                {marketplaceState.token && (
                  <Button
                    type='ghost'
                    icon=''
                    onClick={() => history.push('/register')}
                  >
                    Register
                  </Button>
                )}
              </CustomForm>
            )}
          </Form>
            </FormContainer>
        </Container>
  );
};

export default withRouter(Login);
