import React from 'react';
import {
  Redirect,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';

import InfoCarousel from '../components/InfoCarousel';
import Login from './Login';
import ResetPassword from './ResetPassword';
import Register from './Register';
import NewPassword from './NewPassword';

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #d4d2d0;
    .ant-typography {
    font-family: 'Myriad Pro', Myriad, 'Liberation Sans', 'Nimbus Sans L',
      'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #a5a4a4;
  }
`;

const Container = styled.div`
overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 1px #636262;
  width: 50%;
  min-width: 767px;
  height: 75%;
  min-height: 550px;
  @media (orientation: portrait), (max-height: 600px) {
    width: 100%;
    min-width: 0px;
    height: 100%;
    min-height: 0px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
  padding: 6% 6% 2% 6%;
  height: 100%;
  width: 45%;
  @media (orientation: portrait) {
    width: 100%;
  }
  background-color: #ffffff;
  .ant-btn-primary {
    height: auto;
    width: auto;
    margin-top: 1%;
    padding-top: 1%;
    font-size: 16px;
    background-color: #4c8bc7;
    :hover {
      background-color: #539FE5;
    }
  }
`;

const RightSection = styled.div`
overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 55%;
  @media (orientation: portrait) {
    display: none;
  }
  background-color: #a5a4a4;
`;

const PublicPage = () => {
  return (
    <Page>
      <Container>
        <LeftSection>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/reset-password' component={ResetPassword} />
            <Route exact path='/register' component={Register} />
            <Route exact path='/new-password' component={NewPassword} />
            <Route path='/'>
              <Redirect to='/login' />
            </Route>
          </Switch>
        </LeftSection>
        <RightSection>
          <InfoCarousel />
        </RightSection>
      </Container>
    </Page>
  );
};

export default withRouter(PublicPage);
