import Auth from './auth';
import Categories from './categories';
import Marketplace from './marketplace';
import Quicksight from './quicksight';
import Users from './users';
import Dashboard from './dashboard';

export default {
  Auth,
  Categories,
  Marketplace,
  Quicksight,
  Users,
  Dashboard
};
