import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import rootSaga from '../sagas';

import initialState from './initialState';

export interface StatusState {
  status: boolean;
  error?: string;
}

export interface ResetPasswordState {
  codeRequest: StatusState;
  passSubmit: StatusState;
}

export interface Article {
  id: number;
  name: string;
  content: string;
  order: number;
}

export interface Quicksight {
  id: number;
  name: string;
  order: number;
}

export type Page = Article | Quicksight;

export const isArticle = (obj: Page): obj is Article => {
  return (obj as Article).content !== undefined;
};

export interface Category {
  id: number;
  name: string;
  order: number;
  contentList: Page[];
}

export interface State {
  Auth: {
    login: {
      status: boolean;
      error?: string;
      data?: {
        user?: object;
      };
    };
    register: StatusState;
    token: {
      success: undefined | boolean;
      data: any;
    }
    user: any;
    reset_password: ResetPasswordState;
    change_password: StatusState;
    complete_new_password: StatusState;
  };
  Api: {
    categories: {
      status: boolean;
      error?: string;
      data: Category[];
    };
    quicksight: {
      dashboardUrl: string;
    };
    users: {
      status: boolean;
      error?: string;
      data: any[];
    };
    updateUser: {
      status: boolean;
      error?: string;
      data: any[];
    },
    myUser: {
      status: boolean;
      error?: string;
      data: any;
    }
    addUser: {
      status: boolean;
      error?: string;
      data: any;
    }
  };
  Marketplace: {
    token: null | string;
  };
  Dashboard: {
    selectedKey: string[];
    openKeys: string[];
  };
}

export default () => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const middlewares = applyMiddleware(sagaMiddleware);

  return {
    ...createStore(
      rootReducer,
      initialState as any,
      composeEnhancers(middlewares)
    ),
    runSaga: sagaMiddleware.run(rootSaga)
  };
};
