import Constants from '../../constants';

const defaultStatus = { status: false };

export default (state = defaultStatus, action) => {
  switch (action.type) {
    case Constants.MY_USER_SUCCESS:
      return { status: true, data: action.data };
    case Constants.MY_USER_LOADING:
      return { ...state, status: null };
    case Constants.MY_USER_ERROR:
      return { status: false, error: action.error };
    case Constants.MY_USER_REQUESTED:
      return { ...state, status: null };
    default:
      return state;
  }
};
