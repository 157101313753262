import { combineReducers } from 'redux';
import login from './loginReducer';
import register from './registerReducer';
import token from './tokenReducer';
import user from './userReducer';
import reset_password from './resetPasswordReducer';
import change_password from './changePasswordReducer'
import complete_new_password from './completeNewPasswordReducer';

export default combineReducers({
  login,
  register,
  token,
  user,
  reset_password,
  change_password,
  complete_new_password
});
