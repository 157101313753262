import { put, call } from 'redux-saga/effects';
import Constants from '../constants';
import API from '../api';

function* loginSaga({ email, password, token }) {
  try {
    yield put({ type: Constants.AUTH_LOGIN_LOADING });
    const res = yield call(API.Auth.login, email, password);
    if (res.success && res.data && res.data.token) {
      yield put({
        type: Constants.AUTH_LOGIN_SUCCESS,
        data: { token: res.data.token, user: res.data.user }
      });
    } else if (res.data && res.data.error) {
      if(res.data.user) {
        yield put({
          type: Constants.AUTH_LOGIN_ERROR,
          error: res.data.error,
          data: {
            user: res.data.user
          }
        })
      } else {
        throw Error(res.data.error);
      }
    }
    else throw Error('Error with request');
  } catch (err) {
    yield put({ type: Constants.AUTH_LOGIN_ERROR, error: err.message });
  }
}

function* completeNewPasswordSaga({ email, oldPassword, newPassword }) {
  try {
    yield put({ type: Constants.AUTH_COMPLETE_NEW_PASSWORD_LOADING });
    const res = yield call(API.Auth.completeNewPassword, email, oldPassword, newPassword);
    if (res.success) {
      yield put({
        type: Constants.AUTH_COMPLETE_NEW_PASSWORD_SUCCESS,
      });
    } else if (res.data && res.data.error) throw Error(res.data.error);
    else throw Error('Error with request');
  } catch (err) {
    yield put({ type: Constants.AUTH_COMPLETE_NEW_PASSWORD_ERROR, error: err.message });
  }
}

function* registerSaga({ email, password, token }) {
  try {
    yield put({ type: Constants.AUTH_REGISTER_LOADING });
    const res = yield call(API.Auth.register, {
      email,
      password,
      token
    });
    if (res.success && res.data && !res.data.error)
      yield put({ type: Constants.AUTH_REGISTER_SUCCESS });
    else if (res.data && res.data.error) throw Error(res.data.error);
    else throw Error('An error has occurred');
  } catch (err) {
    yield put({ type: Constants.AUTH_REGISTER_ERROR, error: err.message });
  }
}

function* logoutSaga() {
  try {
    const res = yield call(API.Auth.logout);
    if (res.success) yield put({ type: Constants.AUTH_LOGOUT_SUCCESS });
    else if (res.data && res.data.error) throw Error(res.data.error);
    else throw Error('Error with request');
  } catch (err) {
    yield put({ type: Constants.AUTH_LOGOUT_ERROR, error: err.message });
  }
}

function* requestResetPasswordSaga({ email }) {
  try {
    yield put({ type: Constants.AUTH_RESET_PASS_CODE_REQUEST_LOADING });
    const res = yield call(API.Auth.forgotPassword, email);
    if (res.success && res.data && !res.data.error)
      yield put({ type: Constants.AUTH_RESET_PASS_CODE_REQUEST_SUCCESS });
    else if (res.data && res.data.error) throw Error(res.data.error);
    else throw Error('An error has occurred');
  } catch (err) {
    yield put({
      type: Constants.AUTH_RESET_PASS_CODE_REQUEST_ERROR,
      error: err.message
    });
  }
}

function* restorePasswordSaga({ email, code, password }) {
  try {
    yield put({ type: Constants.AUTH_RESET_PASS_SUBMIT_LOADING });
    const res = yield call(API.Auth.restorePassword, email, code, password);
    if (res.success)
      yield put({ type: Constants.AUTH_RESET_PASS_SUBMIT_SUCCESS });
    else if (res.data && res.data.error) throw Error(res.data.error);
    else throw Error('An error has occurred');
  } catch (err) {
    yield put({
      type: Constants.AUTH_RESET_PASS_SUBMIT_ERROR,
      error: err.message
    });
  }
}

function* changePasswordSaga({ user, oldPassword, newPassword }) {
    try {
    yield put({ type: Constants.AUTH_CHANGE_PASS_SUBMIT_LOADING });
    const res = yield call(API.Auth.changePassword, user, oldPassword, newPassword);
    if (res.success)
      yield put({ type: Constants.AUTH_CHANGE_PASS_SUBMIT_SUCCESS });
    else if (res.data && res.data.error) throw Error(res.data.error);
    else throw Error('An error has occurred');
  } catch (err) {
    yield put({
      type: Constants.AUTH_CHANGE_PASS_SUBMIT_ERROR,
      error: err.message
    });
  }
}

function* getTokenSaga() {
  try {
    const token = yield call(API.Auth.getToken);

    if (!token.data.error && token.data.token)
      yield put({ type: Constants.AUTH_GET_TOKEN_FROM_LS_SUCCESS, token });
    else throw Error('No token available');
  } catch (error) {
    yield put({ type: Constants.AUTH_GET_TOKEN_FROM_LS_ERROR, error });
  }
}

function* getUserSaga() {
  try {
    const res = yield call(API.Auth.getUser);
    if (res.data && !res.data.error)
      yield put({
        type: Constants.AUTH_GET_USER_ID_FROM_LS_SUCCESS,
        data: res.data
      });
    else throw Error('No user ID available');
  } catch (error) {
    yield put({ type: Constants.AUTH_GET_USER_ID_FROM_LS_ERROR, error });
  }
}

export default {
  loginSaga,
  completeNewPasswordSaga,
  registerSaga,
  logoutSaga,
  getTokenSaga,
  getUserSaga,
  restorePasswordSaga,
  requestResetPasswordSaga,
  changePasswordSaga
};
