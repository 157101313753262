import { put, call } from 'redux-saga/effects';
import Constants from '../constants';
import API from '../api';

export function* getQuicksightSaga(qsID) {
  try {
    yield put({ type: Constants.QUICKSIGHT_LOADING });
    const {
      data: { token, tokenID }
    } = yield API.Auth.getToken();

    const res = yield call(
      API.Quicksight.getQuicksight,
      qsID.quicksight.id,
      token,
      tokenID
    );
    if (res.success) {
      const quicksight = res.data;
      yield put({ type: Constants.QUICKSIGHT_SUCCESS, data: quicksight });
    } else if (res.hasOwnProperty('error')) throw Error(res.error);
    else throw Error('Error with request');
  } catch (err) {
    yield put({ type: Constants.QUICKSIGHT_ERROR, error: err.message });
  }
}

export default {
  getQuicksightSaga
};
