import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Spin, Tag, Typography, Menu } from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {State} from '../../../store';
import Actions from '../../../actions';
import InviteUserModal from './InviteUserModal';
import ManageAccessUserModal from './ManageAccessUserModal';
import EditUserModal from './EditUserModal';

const ItemContainer = styled.div`
  width: 90%;
  padding-left: 20px;
`;

const BasicInformationContainer = styled.div`
  padding: 0px 20px;
  padding-left: 0px;
  margin: 0px;
  margin-right: 10px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ListTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 300;
  display: inline;
`;

const UsersInCompanyContainer = styled.div`
  padding-bottom: 20px;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
  padding-bottom: 15px;
  border-bottom: 2px solid #f4f4f4;
`;

const StyledMenu = styled(Menu)`
  width: 100%;
  color: #d7d4d4;
  font-weight: 500;
  font-size: 17px;
  line-height: 35px;

  &.ant-menu-horizontal {
    border-bottom: 1px solid #d7d4d4;
  }

  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
    border-bottom: 2px solid;
  }
`;

const MenuItem = styled(Menu.Item)`
  margin: 0px 30px !important;
  padding: 0px 10px !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const { Title } = Typography;

const Users = () => {
  const dispatch = useDispatch();
  const usersState = useSelector((state: State) => state.Api.users);
  const [inviteUserOpen, setInviteUserOpen] = React.useState(false);
  const [manageUserAccessOpen, setManageAccessUserOpen] = React.useState(false);
  const [editUserOpen, setEditUserOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(undefined);
  const [selectedTab, setSelectedTab] = useState<string | number>('users-active');
  const staticData = usersState.data;
  const loading = usersState.status === null;

  React.useEffect(() => {
      dispatch(Actions.Users.getUsers());
  }, []); //eslint-disable-line

  const filterUsers = (item: any) => {
    if (selectedTab === 'users-active') {
      return item.is_enable === true;
    }
    return item.is_enable ===false;
  };

  const handleManageAccessUserOpen = (user: any) => {
    setSelectedUser(user);
    setManageAccessUserOpen(true);
  };

  const handleEditUserOpen = (user: any) => {
    setSelectedUser(user);
    setEditUserOpen(true);
  }

  const handleInviteUserOpen = () => {
    setInviteUserOpen(true);
  };

  const closeInviteUserModal = () => {
    setInviteUserOpen(false);
    dispatch(Actions.Users.getUsers());
    dispatch(Actions.Users.addUserClear());
  };

  const closeManageAccessUserModal = () => {
    setManageAccessUserOpen(false);
    dispatch(Actions.Users.getUsers());
    dispatch(Actions.Users.updateUserClear());
  };

  const closeEditUserModal = () => {
    setEditUserOpen(false);
    dispatch(Actions.Users.getUsers());
    dispatch(Actions.Users.updateUserClear());
  }

  return (
    <ItemContainer>
        <BasicInformationContainer>
          <TitleContainer>
            <Title level={4}>Users in your Company</Title>
            <Button onClick={() => handleInviteUserOpen()}>Invite a User</Button>
          </TitleContainer>
          <Spin spinning={loading}>
            <StyledMenu onClick={e => setSelectedTab(e.key)} selectedKeys={[selectedTab.toString()]} mode='horizontal'>
              <MenuItem key='users-active'>
                Active
              </MenuItem>
              <MenuItem key='users-inactive'>
                Inactive
              </MenuItem>
            </StyledMenu>
          <UsersInCompanyContainer>
            {staticData && staticData.filter(filterUsers).map((user) => <UserContainer key={user.id}>
              <div className='user-info'>
                <Typography>{user.first_name} {user.last_name}</Typography>
                <ListTypography>
                  {user.email} ({user.role_label}) {!user.is_enable && <Tag color={"error"}>DISABLED</Tag>}
                </ListTypography>
              </div>
              <ButtonsContainer className='user-buttons'>
                <Button onClick={() => handleEditUserOpen(user)}>Edit</Button>
                <Button onClick={() => handleManageAccessUserOpen(user)}>
                  {user.is_enable ? 'Revoke Access' : 'Grant Access'}
                </Button>
              </ButtonsContainer>
            </UserContainer>)}
          </UsersInCompanyContainer>
          </Spin>
          <Title level={4}>Access Type</Title>
          <Typography>Admin</Typography>
          <ListTypography>Allows user to have full permissions over account, including managing users</ListTypography>
          <Typography>Viewer</Typography>
          <ListTypography>Allows a user to view dashboard in the account</ListTypography>
        </BasicInformationContainer>
      <InviteUserModal visible={inviteUserOpen} closeModal={() => closeInviteUserModal()}/>
      <ManageAccessUserModal selectedUser={selectedUser} visible={manageUserAccessOpen} closeModal={() => closeManageAccessUserModal()}/>
      <EditUserModal selectedUser={selectedUser} visible={editUserOpen} closeModal={() => closeEditUserModal()}/>
    </ItemContainer>
  );
};

export default Users;
