import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, Spin } from 'antd';
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';

import AccountSettings from './AccountSettings';
import Users from './Users';
import Products from './Products';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store';
import NotFound from '../../../components/NotFound';
import Actions from '../../../actions';
import NotAvailable from '../../../components/NotAvailable';

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const TabContainer = styled.div`
  display: flex;
  padding: 0px;
  justify-content: center;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  overflow: auto;
  height: 100%;
`;

const StyledMenu = styled(Menu)`
  width: 99%;
  color: #d7d4d4;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;

  &.ant-menu-horizontal {
    > .ant-menu-item a {
      color: #d7d4d4;
      :hover {
        color: #e11f2d;
      }
    }
    > .ant-menu-item-selected a {
      color: #e11f2d;
    }
    border-bottom: 2px solid #d7d4d4;
  }

  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
    border-bottom: 3px solid;
  }
`;

const MenuItem = styled(Menu.Item)`
  margin: 0px 30px !important;
  padding: 0px 10px !important;
  top: 2px !important;
`;

const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

interface Props extends RouteComponentProps {
  setName: (name: string) => void;
}

const Settings = ({ location, history, setName }: Props) => {
  const dispatch = useDispatch();
  const myUser = useSelector((state: State) => state.Api.myUser);
  const role = myUser?.data?.role;
  const fullPath = location.pathname.split('/');
  const [selectedTab, setSelectedTab] = useState<string>(
    fullPath[fullPath.length - 1]
  );
  const rolesLoading = myUser.status === null;

  React.useEffect(() => {
    if (!myUser.status) {
      dispatch(Actions.Users.getMyUser());
    }
    setName('Settings');
  }, []); // eslint-disable-line

  return (
    <div style={{ height: '100%' }}>
      {role ? (
        <ItemContainer>
          <TabContainer>
            <StyledMenu
              onClick={(e) => setSelectedTab(e.key.toString())}
              selectedKeys={[selectedTab.toString()]}
              mode='horizontal'
            >
              <MenuItem
                key='account-settings'
                onClick={() => history.push('/settings/account-settings')}
              >
                ACCOUNT SETTINGS
              </MenuItem>
              {role && role > 9 && (
                <MenuItem
                  key='users'
                  onClick={() => history.push('/settings/users')}
                >
                  USERS
                </MenuItem>
              )}
              {role && role > 9 && (
                <MenuItem
                  key='products'
                  onClick={() => history.push('/settings/products')}
                >
                  PRODUCTS
                </MenuItem>
              )}
            </StyledMenu>
          </TabContainer>
          <ContentContainer>
            <Switch>
              <Route
                exact
                path='/settings/account-settings'
                component={AccountSettings}
              />
              {role && role > 9 && (
                <Route exact path='/settings/users' component={Users} />
              )}
              {role && role > 9 && (
                <Route exact path='/settings/products' component={Products} />
              )}
              <Route path='/settings' component={NotFound} />
            </Switch>
          </ContentContainer>
        </ItemContainer>
      ) : (
        <>{rolesLoading ? <StyledSpin size='large' /> : <NotAvailable />}</>
      )}
    </div>
  );
};

export default withRouter(Settings);
